import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { GoPrimitiveDot } from "@react-icons/all-files/go/GoPrimitiveDot";
import ResponsePopUp from "./ResponsePopUp";
import { useInView } from "react-intersection-observer";
import FilterProduct from "./FilterProduct";

const smooth = "transition-all ease-in-out duration-300";

const ProductFiltered = ({ posts = [], total, title }) => {
  const [open, setOpen] = useState(0);
  const next = () => {
    open + 1 >= Math.ceil(total / postPerPage)
      ? setOpen((prevEff) => prevEff)
      : setOpen((prevEff) => prevEff + 1);
  };

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const postPerPage = 4;

  return (
    <div
      ref={ref}
      className="bg-white relative z-10 pt-4 flex flex-col justify-center items-center px-8 md:px-20 w-full translate-y-1  shadow-xl shadow-stone-900/6 xl:rounded-b-md"
    >
      <div
        className={` flex justify-center items-center text-yellow-710 brightness-105`}
      >
        <GoPrimitiveDot className="mx-2 md:mx-4 text-xl md:text-2xl" />
        <div className="font-runegifter text-5xl">Product</div>
        <GoPrimitiveDot className="mx-2 md:mx-4 text-xl md:text-2xl" />
      </div>

      <FilterProduct />

      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView ? "opacity-100 translate-x-0" : "opacity-0 translate-x-[100px]"
        } w-full mt-5 md:mt-8 flex flex-col justify-center items-center`}
      >
        {/* beans */}
        <div className="font-lufga400 italic text-3xl border-b-2 pb-5 mt-2 md:mt-8 border-stone-300 w-full">
          {title}
        </div>
        <div className="flex flex-wrap justify-center items-center w-full mt-16">
          {posts.slice(0, (open + 1) * postPerPage).map((post) => {
            const { id, title, image, price } = post;
            const pathToImage = getImage(image);

            return (
              <div
                key={id}
                className={`${smooth} group relative w-[210px] h-[350px] mx-6 mb-10 rounded-xl shadow-md hover:shadow-xl shadow-stone-800/10 drop-shadow-md flex flex-col justify-center items-center`}
              >
                <div
                  className={`${smooth} w-full h-full rounded-xl group-hover:-scale-x-[1]`}
                >
                  <GatsbyImage
                    image={pathToImage}
                    alt={title}
                    className={`${smooth} duration-[300ms] group-hover:invisible group-hover:opacity-0 z-20 w-full h-full rounded-xl brightness-[95%] group-hover:brightness-100`}
                  />
                  <div
                    className={`${smooth} z-10 group-hover:shadow-lg group-hover:shadow-stone-900/30 -scale-x-[1] absolute h-full w-full rounded-xl top-0 font-lufga300 text-lg cursor-default bg-gradient-to-t from-gray-100 to-white flex flex-col justify-center items-center`}
                  >
                    <div className="flex flex-col justify-center items-start my-4">
                      <div className="font-lufga300 mt-1 text-[0.9rem]">
                        Price: K {Intl.NumberFormat("en-US").format(price)}
                      </div>
                    </div>

                    <ResponsePopUp
                      title={"See more"}
                      titleIns={title}
                      image={image}
                    />
                  </div>
                </div>
                <div
                  className={`${smooth} duration-200 grid place-content-center w-full py-2 group-hover:invisible group-hover:opacity-0 z-20 absolute bottom-0 rounded-b-xl font-lufga500 text-lg cursor-default mix-blend-screen text-black bg-white`}
                >
                  {title}
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-full md:mt-2 grid place-content-center">
          <button
            onClick={() => {
              next();
              window.dispatchEvent(new Event("resize"));
            }}
            className={`${smooth} ${
              open + 1 >= Math.ceil(total / postPerPage)
                ? "hidden"
                : "bg-stone-900 hover:bg-blue-710 hover:scale-[103%]"
            } text-xl font-lufga300 text-white py-3 px-5 rounded-lg`}
          >
            Load more
          </button>
        </div>
      </div>

      {/* line */}
      <div className="w-[40%] md:w-[20%] h-[3px] bg-stone-300 rounded-xl mt-16 mb-12" />
    </div>
  );
};

export default ProductFiltered;
